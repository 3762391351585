<template>
    <div class="healbox">
        <div class='healing'>
            <div class='trajectory'>
                <div class="text">
                    疗愈轨迹
                    <div class="title_go">
                        <img class="img" :src="goimg3" alt="">
                    </div>
                </div>
                <div class="content">
                    <div class="item">
                        <div>疗愈次数</div>
                        <div>
                            <span class="num">7</span>
                            <span>次</span>
                        </div>
                    </div>
                    <div class="item line">
                        <div>疗愈主题次数</div>
                        <div>
                            <span class="num">14</span>
                            <span>次</span>
                        </div>
                    </div>
                    <div class="item">
                        <div>疗愈时间</div>
                        <div>
                            <span class="num">80</span>
                            <span>天</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class='plan'>
                <div class="text">当前疗愈计划</div>
                <div class="content">
                    <div>疗愈计划1</div>
                    <div class="go">
                        <img class="img" :src="goimg" alt="">
                    </div>
                </div>
            </div>
            <div class='plan'>
                <div class="text">主题疗愈对比报告</div>
                <div class="content">
                    <div>对比报告分析</div>
                    <div class="go">
                        <img class="img" :src="goimg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {
        
    },
    data() {
        return {
            goimg: `${this.$imgDomain}/www/HealthingCore/go2.png`,
            goimg3: `${this.$imgDomain}/www/HealthingCore/go3.png`,
        };
    },
    props:{
        
    },
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.healbox {
    overflow-x: scroll;
}
.healing {
    display: flex;
    .trajectory,
    .plan {
        height: 102px;
        border-radius: 15px;
        flex-shrink : 0;
        overflow: hidden;
        margin-right: 10px;
        .text {
            height: 32px;
            background: #F8A05D;
            color: #fff;
            font-size: .22rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .title_go {
                width: 31px;
                height: 15px;
                position: absolute;
                right: 45px;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
        .content {
            height: calc(100% - 32px);
            width: 100%;
            padding: 10px;
            background: #fff;
            color: #808080;
            font-size: .18rem;
        }
    }
    
    .trajectory {
        width: 324px;
        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .item {
                flex: 1;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                .num {
                    color: #F78935;
                    font-size: .32rem;
                }
            }
            .line {
                border-right: 2px solid #C9C9C9;
                border-left: 2px solid #C9C9C9;
            }
        }
    }
    .plan {
        width: 160px;
        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            .go {
                width: 30px;
                height: 15px;
                border-radius: 15px;
                overflow: hidden;
            }
        }
    }
}

</style>