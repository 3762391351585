<template>
    <div class='love_home'>
        <div class="tabbar">
            <div :class="item.active ? 'isActive' : 'noActive'" v-for="(item, index) in tabItem" :key="index" @click="changeItem(index)">{{item.text}}</div>
        </div>
        <!-- 个人信息 -->
        <InformationCard :personalInfo="personalInfo" ></InformationCard>

        <div class="content">
            <div class="substance">
                <!-- 轮播图 -->
                <swiper :swiperItem="swiperItem"></swiper>
                <!-- 分割线 -->
                <splitline></splitline>
                <!-- 检测分析 -->
                <test :testData="testData"></test>
                <splitline></splitline>
                <titlenav :text="'今日健康'" :goShow="false"></titlenav>
            </div>

            <div class="marg_left">
                <!-- slide -->
                <slide :type="'ordinary'" :slideItem="slideItem14"></slide>
            </div>
            <div class="substance">
                <splitline></splitline>
                <titlenav :text="'月度健康'"></titlenav>
            </div>
            <div class="marg_left">
                <!-- 月度健康 -->
                <slide :type="'healmonth'" :slideItem="slideItem15"></slide>
            </div>
            <div class="substance">
                <splitline></splitline>
                <!-- 一键定制 -->
                <div class="customized">
                    <img class="img" :src="customImg" alt="">
                    <div class="text">一键定制疗愈计划</div>
                </div>
                <splitline></splitline>
                <titlenav :text="'疗愈轨迹'" :goShow="false"></titlenav>
            </div>
            <div class="marg_left">
                <!-- 疗愈轨迹 -->
                <trajectory></trajectory>
            </div>
            <div class="substance">
                <splitline></splitline>
                <swiper :swiperItem="swiperItem2"></swiper>
                <splitline></splitline>
                <!-- 健康锦囊 -->
                <titlenav :text="'健康锦囊'"></titlenav>
                <silkBag :silkImg="silkImg"></silkBag>
                <splitline></splitline>
                <titlenav :text="'产品推荐'"></titlenav>
                <!-- 商品列表 -->
                <goodsList></goodsList>
            </div>
        </div>
    </div>
</template>

<script>
import swiper from "@/components/swiper/index";
import banner from "@/components/banner/index";
import test from "@/components/testing/index";
import slide from "@/components/slide/index";
import goodsList from "@/components/goodsList/index";
import InformationCard from "@/components/InformationCard/index";
import silkBag from "@/components/silkBag/index";
import trajectory from "@/components/trajectory/index";

import data from '@/components/data'

export default {
    components: {
        InformationCard,
        swiper,
        banner,
        test,
        slide,
        goodsList,
        silkBag,
        trajectory
    },
    data() {
        return {
            personalInfo: {
                namne: '',
                yaer: '2022',
                date: '10.16',
                type: 'filialPiety',
                vipUrl: `${this.$imgDomain}/www/HealthingCore/VIP.png`,
                crownUrl: `${this.$imgDomain}/www/HealthingCore/crown.png`,
                Lighting: `${this.$imgDomain}/www/HealthingCore/ligh1.png`,
                notlit: `${this.$imgDomain}/www/HealthingCore/notlit1.png`,
            },
            tabItem: [
                {
                    text: '爸爸',
                    active: true
                },
                {
                    text: '妈妈',
                    active: false
                },
                {
                    text: '叔叔',
                    active: false
                },
                {
                    text: '外公',
                    active: false
                },
                {
                    text: '外婆',
                    active: false
                }
            ],
            swiperItem: [
                {
                    url: `${this.$imgDomain}/www/HealthingCore/old1.png`                    
                },
                {
                    url: `${this.$imgDomain}/www/HealthingCore/old1.png`                    
                },
                {
                    url: `${this.$imgDomain}/www/HealthingCore/old1.png`               
                },
            ],
            swiperItem2: [
                {
                    url: `${this.$imgDomain}/www/HealthingCore/old3.png`                    
                },
                {
                    url: `${this.$imgDomain}/www/HealthingCore/old3.png`                 
                },
                {
                    url: `${this.$imgDomain}/www/HealthingCore/old3.png`                  
                },
            ],
            testData: {
                imgUrl: `${this.$imgDomain}/www/HealthingCore/test3.png`,
                textColor: '#FFF',
                textArr: [
                    {
                        text: '数字健康远程分析',
                        lineColor: '#9966FF'
                    },
                    {
                        text: '数字情绪远程分析',
                        lineColor: '#00CCCC'
                    }
                ]
            },
            silkImg: `${this.$imgDomain}/www/HealthingCore/old4.png`,
            customImg: `${this.$imgDomain}/www/HealthingCore/old2.png`,
            slideItem14: data.slideItem14,
            slideItem15: data.slideItem15,
        };
    },
    created(){
        this.personalInfo.namne = this.tabItem[0].text;
    },
    methods: {
        changeItem(index){
            if(this.tabItem[index].active) {
                return;
            }
            this.tabItem.map((item, i) => {
                if(index == i) {
                    item.active = true
                    this.personalInfo.namne = item.text;
                } else {
                    item.active = false
                }
            })
        }
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.love_home {
    height: 100%;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    .tabbar {
        display: flex;
        justify-content: space-between;
        width: 85%;
        font-size: .2rem;
        margin: 15px 0;
        .noActive {
            color: #898989;
        }
        .isActive {
            color: #F69E5A;
            position: relative;
        }
        .isActive::after {
            content: "";
            width: 80%;
            height: 2px;
            background-color: #F78935;
            border-radius: 2px;
            position: absolute;
            bottom: -2px;
            left: 10%;
        }
    }

    .content {
        width: 100%;
        padding-bottom: 60px;
        border-radius: 30px 30px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #f2f2f2;
        border-top: 1px solid #fff;
        box-shadow: 0 -10px 20px #e2e2e2;
        // background: linear-gradient(to bottom, #f2f2f2, #f2f2f2);
        .substance {
            width: 85%;
        }
        .marg_left {
            width: 100%;
            padding-left: 7.5%;
        }

        .circular {
            display: flex;
            justify-content: space-between;
            .cir_item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                width: 48%;
                .cir_img {
                    width: 100%;
                    border-radius: 50%;
                    overflow: hidden;
                }
                .title {
                    width: 100%;
                    color: #898989;
                    margin-top: 8px;
                    line-height: .2rem;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 20px;
                }
            }
            
            .cir_item:nth-child(1) .title {
                border-right: 2px solid #898989;
            }
        }

        .silkbag {
            width: 100%;
            height: 3.2rem;
            
            background: #fff;
            padding: 22px 0 22px 18px;
            display: flex;
            .silk_img {
                width: 1.4rem;
                height: 2.4rem;
                margin-right: .2rem;
                border-radius: 20px;
                overflow: hidden;
            }
            .textInfo {
                width: calc(100% - 1.6rem);
                color: #898989;
                .textItem {
                    width: 100%;
                    margin-bottom: .1rem;
                    border-bottom: 1px solid #9FA0A0;
                    .title,
                    .subtitle,
                    .tips {
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .title {
                        color: #6a6b6b;
                        font-size: .22rem;
                    }
                    .subtitle {
                        font-size: .18rem;
                        margin-bottom: 5px;
                    }
                    .tips {
                        margin-bottom: 3px;
                    }
                  
                }
                .textItem:last-child {
                    margin: 0;
                    border: none;
                }
            }
            
        }
        
        .customized {
            height: 175px;
            border-radius: 15px;
            overflow: hidden;
            position: relative;
            .text {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                height: 56px;
                width: 100%;
                font-size: 26px;
                color: #fff;
                text-align: center;
                line-height: 56px;
                background: rgba(248,160,93, 0.7);
            }
        }
    }
}
</style>